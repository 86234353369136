@import '../styles/utils.scss';

.asset-card {
  background-color: $color-bg-main;
  border-radius: 12px;
  border: 1px solid #FCBE2D;
  padding: 20px 35px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;

  @media #{$lt-500-devices} {
    padding: 20px 20px;;
  }

  .wallet-info {
    font-size: 14px;
    color: rgba(214, 216, 219, 0.7);

    @media #{$lt-500-devices} {
      flex-direction: column;
    }
    
    .bold {
      font-weight: $fw-bold;
    }

    .wallet-address {
      display: inline-block;
      font-weight: $fw-normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 3;

      @media #{$lt-500-devices} {
        width: 200px;
      }
    }
  }

  .balance {
    font-size: 28px;
    font-weight: $fw-semi-bold;

   
  }

  .status {
    font-size: 16px;
    &.open {
      color: #4AD991; 
    }

    &.suspended {
      color: #FCBE2D; 
    }

    &.closed {
      color: #FD5454;
    }
  }
}
