@import '../../../../styles/utils.scss';

.wallet-management-page {
    
}
.add-buttons{
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
}