@import '../../../../styles/utils.scss';

.liquidity-management-page {
    .page-content {
        &.limit-height {
            height: auto;
        }

        .liquidity-container {

            @media #{$lt-500-devices} {
                flex-direction: column;

                .doughnut-chart-container {
                    order: 2;

                }

                .liquidity-info {
                    .asset-item {
                        gap: 15px;

                    }
                }
            }

            .liquidity-info {
                .liquidity-item {
                    display: flex;
                    justify-content: start;

                    .percentage {
                        font-size: 28px;
                        color: #FFFFFF;
                        font-weight: $fw-bold;
                        white-space: nowrap;


                    }

                    .helper {
                        color: #79859A;
                        font-weight: $fw-semi-bold;
                    }
                }
            }
        }

        .custom-table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            color: #e2e8f0;

            thead {
                tr {
                    th {
                        background-color: inherit;
                        border: none;
                    }
                }
            }
        }
    }
}