@import '../../../styles/utils.scss';

.dashboard-page {

    .assets-container {

        @media #{$lt-500-devices} {
            flex-direction: column;

            .doughnut-chart-container {
                order: 2;

            }
            .assets-info { 
                .asset-item {
                    gap: 15px;

                }
            }
        }

        .assets-info {

            .asset-item {
                .percentage {
                    font-size: 28px;
                    color: #FFFFFF;
                    font-weight: $fw-bold;
                    white-space: nowrap;

        
                }

                .helper {
                    color: #79859A;
                    font-weight: $fw-semi-bold;
                }
            }
        }
    }
    .statistic-container {
        width: 100%;

        @media #{$lt-1080-devices} {
            flex-wrap: wrap;
        }

        .statistic-card {
            min-height: 360px;
            padding: 20px 20px;
            width: 33%;
            background-color: $color-bg-main;
            border-radius: 14px;
            min-height: 370px;
            box-sizing: border-box;

            @media #{$lt-1080-devices} {
                width: 100%;
            }

            &.asset-analytics {
                width: 66%;
                
            @media #{$lt-1080-devices} {
                width: 100%;
            }
            }

            .card-title {
                font-size: 22px;
                color: #fff;
                font-weight: $fw-bold;
            }

            .doughnut-container {
                width: 100%, ;
                height: 200px;

                canvas {
                    width: 146px;
                    height: 146px
                }

            }

            .content {
                width: 100%, ;
                height: 200px;

                .arrow-section {

                    .arrow-container {
                        background-color: #3B465A;
                        width: 41px;
                        height: 41px;
                        border-radius: 50px;
                        color: #fff;
                        cursor: pointer;

                        &:hover {
                            background-color: $color-bg-content;
                            color: $color-accent;
                        }




                        .right-arrow {
                            transform: rotate(180deg);
                        }
                    }
                }
            }


            .item-description {

                .primary {
                    font-size: 18px;
                    color: #fff;
                    font-weight: $fw-bold;
                }

                .secondary {
                    color: #F2AC34;
                    font-weight: $fw-bold;
                }
            }

            .doughnut-chart-legend {

                .value {
                    font-size: 28px;
                    color: #fff;
                    font-weight: $fw-bold;
                }

                .dot {
                    width: 12px;
                    height: 12px;
                    border-radius: 50px;

                    &.provider-1 {
                        background-color: #6494FF;
                    }

                    &.provider-2 {
                        background-color: #C0D2F0;
                    }

                    &.provider-3 {
                        background-color: #7ACB79;
                    }
                }

                .info {
                    color: rgba(255, 255, 255, 0.8);
                }
            }

            .chart-container {
                height: 300px;
            }
            
        }

        
    }
}