

@import '../styles/utils';

.table-container {
    background-color: $color-bg-main;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    color: white;

    @media #{$lt-1080-devices} {
        @include custom-scrollbar;
        overflow-x: scroll;
      }


    .custom-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        color: #e2e8f0;

        thead {
            tr {
                th {
                    background-color: #323D4E;
                    padding: 18px;
                    font-weight: $fw-semi-bold;
                    color: #FFFFFF;
                    font-size: 14px;
                    text-align: left;
                    border-top: 1px solid rgba(207, 207, 207, 0.1142);
                    border-bottom: 1px solid rgba(207, 207, 207, 0.1142);

                    &.secondary-header {
                        color: #979797;
                    }

                    @media #{$lt-1080-devices} {
                        padding: 12px;
                    }

                    &:first-child {
                        border-left: 1px solid rgba(207, 207, 207, 0.1142);
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }

                    &:last-child {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                        border-right: 1px solid rgba(207, 207, 207, 0.1142);
                    }
                }
            }
        }

            tbody {
                tr {
                    
                    td {
                        font-weight: $fw-normal;
                        padding: 18px;
                        border-bottom: 1px solid rgba(207, 207, 207, 0.1142);
                        font-size: 14px;
                        @media #{$lt-1080-devices} {
                            padding: 12px;
                        }
                        .transaction-hash-cell {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                          }
                    }
                }
            }
            
            .received {
                color: $color-accent;
          
              }
              .sent {
                color:  #FD5454;
                
              }

            .status-chip-account-list {
                padding: 4px 8px;
                border-radius: 13px;
                font-size: 14px;
                color: white;
                text-align: center;
                min-width: 80px;
                display: inline-block;
                box-sizing: border-box;

                &.active {
                    background-color: rgba(0, 182, 155, 0.25); 
                    color: #00B69B;
                }

                &.suspended {
                    background-color: rgba(252, 178, 21, 0.25);
                    color: #FCB215;
                }

                &.blocked {
                    background-color: rgba(244, 108, 44, 0.25); 
                    color: #F46C2C;
                }
            }

            .status-chip-account-details {
                padding: 4px 0px;
                border-radius: 12px;
                font-size: 14px;
                min-width: 80px;
                text-align: left;
                display: inline-block;
                box-sizing: border-box;

                &.active {
                    color: #4AD991;
                }

                &.suspended {
                    color: #FCBE2D;
                }

                &.blocked {
                    color: #FD5454;
                }
            }

            .view-link {
                color: $color-accent;
                cursor: pointer;
            }

            .secondary-text {
                font-size: 12px;
                color: #7688A7;
            }
            .truncated-text {
                display: inline-block;
                width: 150px;
                white-space: nowrap;        
                overflow: hidden;           
                text-overflow: ellipsis;    
                // width: 100%;                
              }
        }
    }


   
