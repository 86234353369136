@import '../../../../styles/utils.scss';

.asset-management-page {
    .page-content {
        &.limit-height {
            height: auto;
        }

        .assets-container {

            @media #{$lt-500-devices} {
                flex-direction: column;

                .doughnut-chart-container {
                    order: 2;

                }
                .assets-info { 
                    .asset-item {
                        gap: 15px;

                    }
                }
            }

            .assets-info {

                .asset-item {
                    .percentage {
                        font-size: 28px;
                        color: #FFFFFF;
                        font-weight: $fw-bold;
                        white-space: nowrap;

            
                    }

                    .helper {
                        color: #79859A;
                        font-weight: $fw-semi-bold;
                    }
                }
            }
        }
    }
}