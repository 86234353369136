@import '../styles/utils.scss';

.advanced-filters-container {
    
    @media #{$lt-1080-devices} {
        flex-wrap: wrap;
        justify-content: center;
      }
    

    .amount-container {
        .app-form-element-container {
            gap: 0px;
    
            .app-form-element-label {
                width: 60px;
            }


            input {
                &::placeholder {
                    color: #FFFFFF; 
                  }
            }
        }
    }

    .date-container {
        .app-form-element-container {
            .app-form-element-label {
                width: 30px;
            }
        }
    }

    .app-form-element-container {
        width: auto;
        .app-dropdown-container {
            width: 100px;
    
            &.form {
                .dropdown-header {
                    box-sizing: border-box;
                    height: 30px;
                    width: 100px;
                    border: 0.6px solid rgba(224, 224, 224, 0.1);
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: $fw-semi-bold;
                    color: #FFFFFF;
                    background-color: #323D4E;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    padding-right: 10px;
                }
    
    
                .dropdown-list {
                    width: 130px;
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: $fw-semi-bold;
                    color: #FFFFFF;
                }
            }
        }
    }

    .app-form-input  {
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
        .app-form-element-label {
            padding-bottom: 0px;
        }
        .app-input {
            &.form-field-container {
                box-sizing: border-box;
                height: 30px;
                width: 100px;
                border: 0.6px solid rgba(224, 224, 224, 0.1);
                border-radius: 4px;
                font-size: 12px;
                font-weight: $fw-semi-bold;
                color: #FFFFFF;
                background-color: #323D4E;
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
                input {
                    color: #FFFFFF;
                    font-size: inherit;
                }
            }
        }
    }

    

    .button {
        height: 30px;
        border-radius: 4px;
        width: 50px;
        font-size: 12px;
    }
}   