@import '../styles/utils';

.custom-chart-container {
    box-sizing: border-box;
   

    .custom-chart {
        min-width: 870px;
        width: 100%;
        height: 400px;
    }
    @media #{$lt-1080-devices} {
        @include custom-scrollbar;
        overflow-x: scroll;
      }
}