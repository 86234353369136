@import '../../../styles/utils.scss';

.transaction-hub-page {
  &.page-inner {

    .asset-selected-container {

      .asset-card-container {
        width: 30%;

        @media #{$lt-1080-devices} {
          width: auto;
        }

        .wallet-card {

          &.selected {
            @media #{$lt-1080-devices} {
              width: auto;
            }
          }
        }
      }

      @media #{$lt-1080-devices} {
        flex-direction: column;
        gap: 50px;

        .asset-card-container {
          width: auto;

          .wallet-card {

            &.selected {
              width: auto;
            }
          }
        }
      }

      .wallets-container {


        .wallet-addresses-container {
          max-height: 100px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 10px;

          }

          &::-webkit-scrollbar-track {
            width: 2px;
            background-color: transparent;
            border-radius: 2px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-accent;
            border-radius: 10px;
            width: 10px;

          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: #00D6B6;
          }

          &::-webkit-scrollbar-button {
            display: none;
          }

          .wallet-addresses-label {
            font-size: 12px;
            color: $color-text-label;
            font-weight: $fw-bold;
          }

          .wallet-address-container {
            width: 100%;
            color: #D6D8DB;
            font-size: 14px;
            padding-bottom: 15px;
            border-bottom: 1px solid $color-text-label;
            overflow: hidden;

            .wallet-alias {
              display: inline-block;
              width: 30%;
              font-weight: $fw-bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media #{$lt-500-devices} {
                width: 100px;
              }
            }

            .wallet-address {
              width: 200px;
              display: inline-block;
              font-weight: $fw-normal;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex-shrink: 3;
            }


          }
        }
      }
    }
  }
}