@import '../../../../styles/utils.scss';

.transactions-details-page {
    .page-header {

        @media #{$lt-500-devices} {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }

        .wallet-info {
            color: #FFFFFF;


            .address {
                font-size: 14px;
                font-weight: $fw-bold;

                @media #{$lt-500-devices} {
                    &.ellipsis {
                        width: 300px;
                        display: inline-block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }


    .page-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .content-title {
            font-size: 28px;
            font-weight: $fw-bold;
            color: $border-color-form-field;
        }

        .section {
            padding: 40px 35px;
            background-color: #323D4E;
            border-radius: 12px;

            @media #{$lt-500-devices} {
                padding: 30px 10px;
            }

            .ellipsis {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .item {

                @media #{$lt-500-devices} {
                    gap: 20px;
                }

                &.has-address {
                    @media #{$lt-500-devices} {
                        flex-direction: column;
                    }
                }


                .hidden-icon {
                    visibility: hidden;
                    min-height: 12px;
                    min-width: 12px
                }

                .label {
                    width: 400px;
                    color: #FFFFFF;
                    font-weight: $fw-bold;
                }

                .value {
                    color: #FFFFFF;
                    font-size: 14px;

                    .accent {
                        color: $color-accent;
                    }

                    .status-chip {
                        padding: 4px 8px;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        gap: 5px;
                        align-items: center;
                        color: white;
                        min-width: 80px;
                        font-weight: $fw-semi-bold;

                        &.active {
                            border: 0.6px solid $color-accent;
                            background-color: rgba(0, 182, 155, 0.10);
                            color: $color-accent;
                        }

                        &.suspended {
                            background-color: rgba(252, 178, 21, 0.25);
                            color: #FCB215;
                        }

                        &.blocked {
                            background-color: rgba(244, 108, 44, 0.25);
                            color: #F46C2C;
                        }
                    }
                }
            }
        }

        .transaction-action {
            padding: 20px 40px;
            padding-bottom: 0px;

            @media #{$lt-500-devices} {
                flex-direction: column;
                gap: 20px;
                padding: 20px 10px;
            }

            .label {
                width: 400px;
                color: #FFFFFF;
                font-weight: $fw-bold;
            }

            .value {
                color: #FFFFFF;
                font-size: 14px;

                @media #{$lt-500-devices} {
                    flex-direction: column;
                    align-items: flex-start
                }

                .accent {
                    color: $color-accent;
                }

                .status-chip {
                    padding: 4px 8px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    align-items: center;
                    color: white;
                    min-width: 80px;
                    font-weight: $fw-semi-bold;

                    &.active {
                        border: 0.6px solid $color-accent;
                        background-color: rgba(0, 182, 155, 0.10);
                        color: $color-accent;
                    }

                    &.suspended {
                        background-color: rgba(252, 178, 21, 0.25);
                        color: #FCB215;
                    }

                    &.blocked {
                        background-color: rgba(244, 108, 44, 0.25);
                        color: #F46C2C;
                    }
                }
            }
        }

        .helper-text-container {
            .helper-text {
                font-size: 12px;
                width: 80%;
                color: #79859A;

                .accent {
                    color: $color-accent;
                }
            }
        }
    }
}