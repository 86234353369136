@import '../styles/utils.scss';



.switch-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .label {
    color: $color-text-label; 
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s ease; 

    &.active-label {
      color: $color-accent; 
    }
  }

  .switch-toggle {
    align-self: flex-start;
    min-width: 50px;
    height: 25px;
    background-color: #4a5568;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;

    .toggle-button {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: $color-accent;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease;
      left: 5%;

      &.active {
        transform: translate(25px, -50%);
      }
    }
  }
}