@import '../styles/utils.scss';


.table-filters-container {

    @media #{$lt-1080-devices} {
        flex-direction: column;
        gap: 20px;
    }

    .filters-container {

        @media #{$lt-1080-devices} {
            flex-direction: column;
            gap: 20px;
        }

        .filters-section {

            @media #{$lt-1080-devices} {
                gap: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }



            .advanced-filter-container {
                @media #{$lt-1080-devices} {
                    order: 2;
                }

                &.active {
                    color: $color-accent;

                    .button {
                        border-color: $color-accent;
                        color: $color-accent;

                    }
                }
            }
        }

        .month-selector {
            width: 50%;
            @media #{$lt-1080-devices} {
                order: 2;
            }
        }

        .separator {
            width: 100%;
            height: 1.5px;
            background-color: $color-text-label;
        }


        .advanced-filter {
            width: 25%;

            @media #{$lt-1080-devices} {
                order: 3;
            }
        }

        .table-pagination {
            width: 25%;
        }

    }



    .app-dropdown-container {
        width: 130px;

        &.form {
            .dropdown-header {
                box-sizing: border-box;
                height: 30px;
                width: 130px;
                border: 0.6px solid rgba(224, 224, 224, 0.1);
                border-radius: 4px;
                font-size: 12px;
                font-weight: $fw-semi-bold;
                color: #FFFFFF;
                background-color: #323D4E;
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
            }


            .dropdown-list {
                width: 130px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: $fw-semi-bold;
                color: #FFFFFF;
            }
        }
    }


}