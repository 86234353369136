@import 'src/styles/utils';

.app-form-textarea {
    margin-bottom: 20px;
    width: 100%;
    max-width: none;
    .form-element-error {
      font-size: 12px;
      color: #f15d60;
      margin-top: 5px;
    }
  }
  