.analytics-chart-container {
    box-sizing: border-box;
    height: 100%; 
    display: flex;

    .analytics-chart {
        width: 100%;
        height: 100%;
    }
  
}