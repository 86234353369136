
$color-bg-content: #1B2431;
$color-bg-main: #273142;
$bicolor-primary: #EA2F3F;
$bicolor-secondary: #FFFFFF;
$color-text-secondary: #F2F2F2;
$color-text-primary: #00B69B;
$color-bg-green: rgba(0, 182, 155, 0.1);

$color-bg-blue: #4880FF;
$color-bg-blue-disabled: rgba(72, 128, 255, 0.3); ;

$color-bg-dark: #000000;
$color-bg-light: #FFFFFF;
$color-text-dark: #000000;
$color-text-light: #FFFFFF;
$color-accent: #00B69B;
$color-accent-disabled: rgba(72, 128, 255, 0.3); ;
$color-success: #00BA00;
$color-error: #F44336;


// disabled/inactive elements
$color-bg-disabled: #F4F4F4;
$color-text-disabled: #717171;
$color-text-disabled-secondary: #979797;
$color-shadow: #E5E5E5;
$color-separator: #979797;
$color-separator-secondary: #E4E4E4;
$color-text-disabled-light: #C7C7C7;

/**
 * Spacing
 */
$layout-side-padding: 20px;
$layout-side-padding-sm: 10px;
$layout-side-padding-xxs: 5px;
//$page-padding-h: 16px;

/**
 * Fonts
 */
$fw-normal: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-semi-bold-plus: 750;
$fw-extra-bold: 800;


/**
 * Form elements
 */
$form-element-max-width: 450px;
$color-bg-form-field: #F4F4F4;
$border-color-form-field: #79859A;
$color-text-form-field: #717171;
$color-text-label: #727E92;
$color-text-placeholder: #979797;

/**
 * Media query
 */
$sm-min-size: 600px;
$md-min-size: 960px;
$lg-min-size: 1280px;
$xl-min-size: 1920px;

$lt-1080-devices: "only screen and (max-width: 1079px)";
$lt-800-devices: "only screen and (max-width: 799px)";
$lt-500-devices: "only screen and (max-width: 499px)";
$lt-400-devices: "only screen and (max-width: 399px)";
$lt-375-devices: "only screen and (max-width: 374px)";
$xs-devices: "only screen and (max-width: #{$sm-min-size - 1})";
$gt-xs-devices: "only screen and (min-width: #{$sm-min-size})";
$sm-devices: "only screen and (max-width: #{$md-min-size - 1})";
$gt-sm-devices: "only screen and (min-width: #{$md-min-size})";
$md-devices: "only screen and (max-width: #{$lg-min-size - 1})";
$gt-md-devices: "only screen and (min-width: #{$lg-min-size})";
$lg-devices: "only screen and (max-width: #{$xl-min-size - 1})";
$gt-lg-devices: "only screen and (min-width: #{$xl-min-size})";
