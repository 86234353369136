@import '../../../../styles/utils.scss';

.initiate-transfer-page {
    .page-content {
        display: flex;
        flex-direction: row;
        padding: 80px 30px;

        @media #{$lt-500-devices} {
            flex-direction: column;
        }

        .transfer-details-container {
            flex: 1;

            .app-form-element-container {
                max-width: 100%;
            }

            .app-dropdown-container {
                width: 100%;
            }

            .to-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .switch-toggle-container {
                    align-self: flex-end;
                }

                .app-form-element-container {
                    min-width: 400px;

                    .dropdown-header {
                        max-width: 400px;
                        box-sizing: border-box;
                        .icon-label-container {
                            overflow: hidden;
                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    }

                    .dropdown-list {
                        max-width: 400px;
                        min-width: 400px;
                    }
                }
            }

            .amount-button-container {
                @media #{$lt-500-devices} {
                    flex-direction: column;
                    align-items: center;
                }
                .amount-input-container {
                    flex: 1;
                    @media #{$lt-500-devices} {
                        width: 100%;
                    }    
                    .amount-label {
                        color: $color-text-label;
                        font-size: 12px;
                        margin-bottom: 5px;
                        font-size: $fw-bold;
                        display: block;
                    }

                    .amount-input {
                        display: flex;
                        flex-direction: column;
                        border: 1px solid #79859A;
                        border-radius: 12px;
                        background-color: #2D3748;
                        color: white;

                        .main-amount {
                            padding: 5px 15px;

                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            font-weight: bold;
                            border-bottom: 1px solid #79859A;

                            .amount-value-input {
                                background: transparent;
                                border: none;
                                color: $color-accent;
                                font-size: 14px;
                                font-weight: bold;
                                outline: none;
                                width: 120px;
                            }

                            .currency {
                                color: #00B69B;
                                margin-left: 10px;
                            }
                        }

                        .sub-amounts {
                            padding: 10px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 10px;
                            color: $color-text-label;

                            .sub-options {

                                .sub-option {
                                    color: $color-text-label;

                                    &.active {
                                        color: #FFFFFF;
                                    }
                                }
                            }

                            .net-amount,
                            .gross-amount {
                                color: white;
                                font-weight: bold;
                            }

                            .gross-amount {
                                color: #5A6779;
                            }
                        }
                    }
                }

                .button {
                    @media #{$lt-500-devices} {
                        min-height: 50px;
                        width: 100%;
                    }    
                    flex: 1;
                }
            }
        }

        .transfer-info-container {
            flex: 1;
            box-sizing: border-box;

            .waiting-text {
                font-weight: $fw-bold;
                color: $color-text-label;
            }

            .initiate-text {
                font-size: 14px;
                font-weight: $fw-bold;
                color: #FFFFFF;
            }
        }
    }


}