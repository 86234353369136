@import '../styles/utils';

.navbar-container {
    box-sizing: border-box;
    height: 70px;
    padding: 15px 30px;
    background-color: $color-bg-main;


    @media #{$lt-500-devices} {
        gap: 10px;
        padding: 15px 15px;
    }

    .logo-container {
        width: 100%;
        max-width: 240px;
        box-sizing: border-box;
        height: 70px;
        padding: 15px 0px;
        font-size: 20px;

        @media #{$lt-500-devices} {
            gap: 5px;
                width: auto;
    
        }

        img {
            width: 30px;
        }

        .logo-title {
            .title-primary {
                color: $bicolor-primary;
                font-weight: $fw-extra-bold;
            }

            .title-secondary {
                color: $bicolor-secondary;
            }

            @media #{$lt-500-devices} {
                font-size: 14.6px;
                align-self: flex-end;
            }
        }
    }

    .burger-menu-and-search-container {
        width: 100%;
        @media #{$lt-500-devices} {
            width: 50%;
            order: 3;
        }

        .searchbar {
            box-sizing: border-box;
            height: 38px;
            width: 200px;
            padding: 0 20px;
            border-radius: 19px;
            background-color: #323D4E;
             display: flex;
             align-items: center;
             span {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.8);;
             }
            @media #{$lt-500-devices} {
                order: 1;
            }
        }

        svg {
            @media #{$lt-500-devices} {
                order: 2;
                cursor: pointer;
                width: 50px;
                height: 50px;
            }
        }
    }

    .buttons-container {


        @media #{$lt-1080-devices} {
            gap: 10px;
            width: 100%;
        }

        .language-text {
            color: $color-text-secondary;
        }

        .user-info {
            color: #FFFFFF;

            .username {
                font-size: 14px;
                font-weight: $fw-bold;
                letter-spacing: 1px;
            }

            .role {
                font-size: 12px;
                font-weight: $fw-semi-bold;
            }
        }


        .user-menu {
            position: relative;

            svg {
                border-radius: 50px;
                cursor: pointer;
                transition: background-color 0.2s;
                
                &:hover {
                    background-color: $color-accent;
                    border-radius: 50px;
                }
            }

            .usermenu-list {
                position: absolute;
                top: 100%;
                right: 0px;
                width: 100px;
                color: $color-accent;
                background-color: $color-bg-main;
                border-radius: 15px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                z-index: 1000;
                max-height: 200px;
                overflow-y: auto;

                .dropdown-option {
                    text-align: center;
                    padding: 10px 0px;
                    cursor: pointer;
                    transition: background-color 0.2s;

                    &:hover {
                        background-color: $color-bg-content;
                        border-radius: 15px;
                    }
                }
                }
            }
        }
    }