@import './styles/common-styles';
@import './styles/utils';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

html,
body,
#root {
  height: 100%;
  margin: 0;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;

  }

  &::-webkit-scrollbar-track {
    width: 4px;
    background-color: #5D708B;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-accent;
    border-radius: 10px;
    width: 10px;

  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #00D6B6;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





p {
  margin: 0;
}