@import '../../styles/utils.scss';


.login-page-container {
    width: 100%;

    .reset-password-link {
        color: $color-text-disabled-light;
        cursor: pointer;
    
        span {
            color: #F15D60;
        }
    }


        .button {
            width: 100%;
        }
  


    .error-message {
        color: $color-error;
        font-size: 15px;
        height: 15px;
        line-height: 15px;
    }
}