@import '../styles/utils.scss';

.wallet-card {
    background-color: $color-bg-main;
    border-radius: 12px;
    border: 1px solid #313D4F;
    padding: 16px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    

    &.hoverable {
      &:hover {
        cursor: pointer;
        border-color: #FCBE2D;
      }
    }
    
    &.selected{
      border-color: #FCBE2D;
    }
    .wallet-card-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  
    .wallet-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #A0AEC0;
  
     
    }
  
    .wallet-card-total {
      font-size: 28px;
      font-weight: $fw-bold;
      color: #FFFFFF;
      margin-top: 8px;
    }
  
      .percentage-change {
        font-size: 14px;
        color:  #FFFFFF;
  
        &.positive {
          color: $color-accent; 
        }
  
        &.negative {
          color: #e53e3e; 
        }
      }
    
  }
  