@import 'src/styles/utils';

.app-input {

  &.hasIcon {
    gap: 15px;
  }
  input {
    font-family: inherit;
    font-size: 14px;
    background: transparent;
    border: none;
    outline: none;
    color: $color-accent;
    width: 100%;
    font-weight: $fw-bold;
    &::placeholder {
      color: $color-accent;
    }
  }
  button {
    margin: 5px 0px;
    &.button {
      font-weight: $fw-normal;
    }
  }
  &.auth {
    background: #F4F4F4;
    input {
      font-size: 16px;
      background: #F4F4F4;
      border: none;
      outline: none;
      color: #717171;
      width: 100%;

      &::placeholder {
        color: #979797;;
      }
    }
  }
}