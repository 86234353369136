@import '../../../../styles/utils.scss';

.transactions-list-page {
  

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border: 0.6px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    background-color: #323D4E;
    cursor: pointer;


    &:hover {
      border-color: $color-accent;

      svg {
        path {
          fill: $color-accent;
        }
      }
    }
  }

  .hash-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .page-header {

    .wallet-info {
      color: #FFFFFF;

      .token {
        font-size: 16px;
        font-weight: $fw-bold;
      }


      .asset-info {
        .asset {
          font-size: 14px;
          font-weight: $fw-semi-bold;

        }
      }
    }
  }

}