@import 'src/styles/utils';

.app-dropdown-container {
    width: 100%;
    position: relative;
    font-size: 16px;

    .dropdown-header {
      

        .app-input {
            width: 100%;
            font-family: inherit;
            font-size: 14px;
            background: transparent;
            border: none;
            outline: none;
            color: $color-accent;
            width: 100%;
            font-weight: $fw-bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &::placeholder {
                color: $color-accent;
            }
        }
    }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        color: $color-accent;
        background-color: $color-bg-main;
        border-radius: 15px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        max-height: 200px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .dropdown-option {
            padding: 10px 35px;
            cursor: pointer;
            transition: background-color 0.2s;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                background-color: #323D4E;
                border-radius: 15px;
            }
        }
    }
}