@import '../../../../styles/utils.scss';

.add-account-page {
    .page-content {

        @media #{$lt-500-devices} {
            flex-direction: column;
            align-items: center;
        }

        .account-info-container {
            flex: 1;
            padding-top: 20px;
            padding-left: 30px;

            @media #{$lt-500-devices} {
                width: 100%;
                padding: 0px;
            }

            .form-container-100 {

                @media #{$lt-500-devices} {
                    width: 100%;

                }


            }


           

            .wallet-addresses-label {
                font-size: 12px;
                color: $color-text-label;
                font-weight: $fw-bold;
            }

            .add-wallet-container {
                width: 100%;

                .add-wallet-text {
                    font-size: 14px;
                    color: $color-accent;
                    font-weight: $fw-bold;
                    font-family: inherit;
                    cursor: pointer;
                }

                .add-wallet-line {
                    width: 100%;
                    height: 0.6px;
                    background-color: $color-accent;
                }
            }

            .wallet-addresses-container {
                overflow-y: scroll;
                padding-right: 30px;

                @media #{$lt-500-devices} {
                    width: 100%;
                    padding: 0px;
                }



                &::-webkit-scrollbar {
                    width: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $color-text-label;
                    border-radius: 20px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: darken($color-text-label, 10%);
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-button {
                    display: none;
                }

                .wallet-addresses-label {
                    font-size: 12px;
                    color: $color-text-label;
                    font-weight: $fw-bold;
                }

                .wallet-address-container {
                    width: 100%;
                    color: #D6D8DB;
                    font-size: 14px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid $color-text-label;

                    .wallet-label {
                        font-weight: $fw-bold;
                    }

                    .wallet-address {
                        font-weight: $fw-normal;
                    }
                }
            }

            .buttons-container {
                flex: 1;
                width: 100%;
                box-sizing: border-box;
                padding-right: 30px;

                @media #{$lt-500-devices} {
                    flex-direction: column;
                    padding: 0px;

                    .button {
                        width: 100%;
                    }
                }
            }
        }

        .wallet-info-container {
            box-sizing: border-box;
            padding: 20px 20px;
            height: 100%;
            flex: 1;
            border: 0.6px solid $color-accent;
            border-radius: 12px;


            .form-container-100 {
                width: 100%;

                .app-form-element-container {
                    max-width: none;
                }
            }
        }
    }
}