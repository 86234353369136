@import '../../../../styles/utils.scss';

.add-crypto-wallet-page {

    .page-content {
    @media #{$lt-500-devices} {
        flex-direction: column;
        gap: 20px;
        > * {
            max-width: 100%;
        }
      }

    .buttons-container {
        max-width: 450px ;
        justify-content: end;
        width: 100%;
        box-sizing: border-box;
    }
}
}