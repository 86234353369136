@import '../styles/utils.scss';
.side-menu-container {
    max-width: 240px;
    min-height: 100vh;
    height: 100%;
    background-color: $color-bg-main;

    @media #{$lt-500-devices} {
        position: absolute;
        top: 70px;
        left: 0;
        height: 100%;
        width: 240px;
        z-index: 1000;
        background-color: $color-bg-main;
        transition: transform 0.3s ease;
        transform: translateX(-100%);

        &.open {
            transform: translateX(0); 
        }
    }

    .menu-buttons-container {
        box-sizing: border-box;
        padding: 10px 20px 30px 0px;
        &.main {
            border-bottom: 1px solid rgba(224, 224, 224, 0.2);
        }

        .item-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            
            .is-active-bar {
                height: 100%;
                width: 0;  
                background-color: transparent;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                transition: width 0.4s ease, background-color 0.3s ease;
            
                &.active {
                    width: 6px;  
                    background-color: $color-bg-blue;
                }
            }

            .button-container {
                min-height: 50px;
                height: 100%;
                min-width: 180px;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding-left: 20px;
                transition: background-color 0.3s ease;

                &.has-options {
                    height: auto;
                    gap: 10px;
                }

                &:hover {
                    background-color: lighten($color-bg-blue, 10%);
                }

                &.active {
                    background-color: $color-bg-blue;
                }

               

                .button-text {
                    font-size: 14px;
                    font-weight: $fw-semi-bold;
                    color: #FFFFFF;
                    opacity: 0.7;
                    transform: scale(1);
                    transition: opacity 0.3s ease, transform 0.3s ease;
            
                    &.active {
                        opacity: 1;
                        transform: scale(1.1); 
                    }
                }
                .submenu {
                    flex-direction: column;
                    gap: 20px;
                    transition: opacity 0.3s ease, transform 0.3s ease;
                    .submenu-item {
                        color: rgba(255, 255, 255, 0.5);
                        font-size: 10px;
                        cursor: pointer;
                        transition: color 0.3s ease, opacity 0.3s ease, transform 0.3s ease; 
                        &:hover {
                            color: #FFFFFF; 
                            transform: scale(1); 
                        }
                        &.active {
                            color: #FFFFFF; 
                        }
                    }
                }
    
                &.active .submenu {
                    display: flex; 
                }
            }

            &.active {
                .button-container {
                    background-color: $color-bg-blue;

                    &.has-options {
                        padding: 15px 0px;
                        padding-left: 20px;
                        gap: 20px;
                    }
                }
            }
        }
    }
}
