@import '../../../../styles/utils.scss';

.approve-request-page {



    .page-content {
        height: auto;
        padding: 50px 50px;

        @media #{$lt-1080-devices} {
            padding: 20px 50px;
            flex-direction: column;
        }
    }

    .subtitle {
        font-weight: $fw-bold;
        font-size: 16px;
        color: $color-text-label;
    }

    .approve-text-section {
        flex: 1;

        .message-text {
            font-size: 14px;
            color: #FFFFFF;
            width: 60%;
        }

        .fullname {
            color: $color-accent;
            font-size: 14px;
            font-weight: $fw-bold;
        }
    }


    .transfer-details-section {

        .transfer-details-container {

            .from-to-section {
                @media #{$lt-1080-devices} {
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 40px;
                }
            }

            .separator {
                width: 100%;
                border-bottom: 1px solid #596578;
            }

            .asset-amount-section {
                @media #{$lt-1080-devices} {
                    flex-direction: column;
                    gap: 40px;
                    
                }
            }

            
            .item {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 20px;


                .label {
                    color: $color-text-label;
                    font-size: 12px;
                    font-weight: $fw-bold;
                }

                .value {
                    color: $color-accent;
                    font-size: 14px;
                    font-weight: $fw-bold;
                }
            }

            .amount-item {
                border: 0.6px solid #79859A;
                border-radius: 12px;

                .amount-section {
                    display: flex;
                    text-align: center;
                    padding: 5px 15px;

                    .amount {
                        font-size: 14px;
                        font-weight: $fw-bold;
                        color: $color-accent;
                    }
                }



                .sub-amounts {
                    padding: 5px 15px;
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 10px;
                    color: $color-text-label;

                    .sub-options {

                        .sub-option {
                            color: $color-text-label;

                            &.active {
                                color: #FFFFFF;
                            }
                        }
                    }

                    .net-amount,
                    .gross-amount {
                        color: white;
                        font-weight: bold;
                    }

                    .gross-amount {
                        color: #5A6779;
                    }
                }
            }


        }

        .buttons-container {

            @media #{$lt-500-devices} {
                flex-direction: column;

                .button {
                    min-height: 50px;
                    width: 100%;
                }
            }

            .button {

                &.form-outline {
                    color:  #FD5454;
                    border: 1px solid #FD5454;
                }

                flex: 1;
                max-width: none;
            }

        }
    }
}