@import 'src/styles/utils';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  font-size: 16px;
  gap: 10px;
  font-weight: $fw-bold;
  cursor: pointer;

  
  &.blue {
    background: $color-bg-blue;
    border: 1px solid $color-bg-blue;
    color: white;

    &:disabled {
      background:  $color-bg-blue-disabled;
      border: 1px solid  $color-bg-blue-disabled;
    }
  }

  &.accent {
    justify-content: space-evenly;
    min-width: auto;
    border-radius: 4px;
    height: 30px;
    min-width: 120px;
    background-color: rgba(0, 182, 155, 0.1); 
    border: 1px solid $color-accent;
    color: $color-accent;
    font-size: 12px;
    font-weight: $fw-semi-bold;
  }

  &.form {
    height: 50px;
    width: 100px;
    min-width: 100px;
    border-radius: 12px;
    background-color: $color-accent;
    color: #FFFFFF;
    border: 0px;
    &:disabled {
      cursor: default;
      background-color: #323D4E;
      border: 0.6px solid ;
      color: $color-text-label
    }

  }
  &.filters-button {
    padding: 0px 10px;
    height: 30px;
    // box-sizing: border-box;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #323D4E;
    color: #ffffff;
    border: 0.6px solid rgba(224, 224, 224, 0.1);
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: $color-bg-content;
    }

    span {
      text-wrap: nowrap;
    }

    &:disabled {
        background-color: #2d3748;
        color: #a0aec0;
        cursor: not-allowed;
        border: 1px solid #a0aec0;
    }
}

  &.form-outline {
    height: 50px;
    width: 100px;
    min-width: 100px;
    border-radius: 12px;
    background-color: transparent;
    color: $color-accent;
    border: 1px solid $color-accent;
  }

  &.outline-light {
    background: transparent;
    border: 1px solid $color-text-light;
    color: $color-text-light;
  }
}