@import '../styles/utils.scss';

.copy-text-container {
    cursor: pointer;

    svg {
        color: #79859A;
        transition: fill 0.2s ease;

        &:hover {
            color:  #FFFFFF; 
        }
        &.copied {
            color: $color-accent;
        }
    }

  
}
