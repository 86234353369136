@import '../../../../styles/utils.scss';

.approve-transfer-page {

    .page-content {
        height: auto;
        padding: 80px 30px;

        @media #{$lt-500-devices} {
            padding: 20px 50px;
            flex-direction: column;
        }
    }

    .transfer-details-container {
        

        @media #{$lt-500-devices} {
            align-items: flex-start;
            flex-direction: column;
            gap: 40px;
        }

        .from-to-section {
            @media #{$lt-500-devices} {
                align-items: flex-start;
                flex-direction: column;
                gap: 40px;
            }
        }
        .asset-amount-section {
            @media #{$lt-500-devices} {
                flex-direction: column;
                gap: 40px;
                
            }
        }

        .separator {
            width: 100%;
            border-bottom: 1px solid #596578;
        }


        .item {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 20px;

            @media #{$lt-500-devices} {
                width: 100%;
                gap: 10px;
            }


            .label {
                color: $color-text-label;
                font-size: 12px;
                font-weight: $fw-bold;
            }

            .value {
                color: $color-accent;
                font-size: 14px;
                font-weight: $fw-bold;


                &.trunctated-text {
                    width: 150px;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .amount-item {
            border: 0.6px solid #79859A;
            border-radius: 12px;

            .amount-section {
                display: flex;
                text-align: center;
                padding: 5px 15px;

                .amount {
                    font-size: 14px;
                    font-weight: $fw-bold;
                    color: $color-accent;
                }
            }



            .sub-amounts {
                padding: 5px 15px;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                font-size: 10px;
                color: $color-text-label;

                .sub-options {

                    .sub-option {
                        color: $color-text-label;

                        &.active {
                            color: #FFFFFF;
                        }
                    }
                }

                .net-amount,
                .gross-amount {
                    color: white;
                    font-weight: bold;
                }

                .gross-amount {
                    color: #5A6779;
                }
            }
        }
    }

    .approve-list-container {



        .subtitle {
            padding-right: 10px;
            color: $color-text-label;
            font-weight: $fw-bold;

            .status {
                text-align: left;
                min-width: 90px;
            }
        }

        .approval-items {
            padding-right: 10px;
            max-height: 400px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;

            }

            &::-webkit-scrollbar-track {
                width: 4px;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-accent;
                border-radius: 10px;
                width: 10px;

            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #00D6B6;
            }



            .approval-item {
                font-size: 14px;
                color: #FFFFFF;
                font-weight: $fw-bold;

                .helper {
                    font-weight: $fw-normal;
                    color: $color-text-label;
                }

                .status {
                    padding: 4px 0px;
                    border-radius: 12px;
                    font-size: 14px;
                    min-width: 80px;
                    text-align: left;
                    display: inline-block;


                    &.approved {
                        color: #4AD991;
                    }

                    &.pending {
                        color: #FCBE2D;
                    }

                    &.blocked {
                        color: #FD5454;
                    }
                }
            }
        }
    }
}