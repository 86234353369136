@import '../../styles/utils';

.app-layout {
  background-color: $color-bg-content;
  height: 100%;
  position: relative;

  

  @media #{$lt-500-devices} {
    overflow-y: scroll;
}

  &::-webkit-scrollbar {
    width: 5px;
    @media #{$lt-1080-devices} {
      display: none;
  }
  }

  &::-webkit-scrollbar-track {
    width: 4px;
    background-color: #5D708B;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-accent;
    border-radius: 10px;
    width: 10px;

  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #00D6B6;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }



  .page-container {
    width: 100%;
    .page-title {
      margin-left: 25px;
      font-size: 25px;
      font-weight: $fw-bold;
      color: #FFFFFF;

      @media #{$lt-1080-devices} {
        margin: 0px;
    }
    }

    .page-inner {
      background-color: $color-bg-content;
      flex: 1;
      height: 100%;
      padding: 20px 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      box-sizing: border-box;

      @media #{$lt-1080-devices} {
        overflow: auto;
     
        @include page-side-padding
    }

      .page-content {
        border-radius: 12px;
        @media #{$lt-1080-devices} {
     
          @include page-side-padding
      }
      }
    }
  }
}