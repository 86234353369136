@import '../styles/utils.scss';

.statistical-info-card {
    padding-top: 30px;
    align-items: center;
    color: #fff;
    @media #{$lt-500-devices} {
        padding-top: 0px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        gap: 40px;
        > * {
            max-width: 100%;
        }
      }

    .flat-values-container {
        width: 50%;
        @media #{$lt-500-devices} {
            width: 100%;
            flex-direction: column;
            gap: 40px;
            > * {
                width: 100%;
            }
          }
        .title {
            margin-top: 10px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
        }

        .value-container {
            display: flex;
            flex-direction: column;

            .total {
                font-weight: $fw-bold;
                font-size: 36px;
            }

            .change {
                font-size: 16px;
                color: $color-accent;

                .helper {
                    color: #FFFFFF;
                }
            }
        }
    }

    .percentage-details-container {
        color: $color-accent;
        width: 50%;
        @media #{$lt-500-devices} {
            width: 100%;
            flex-direction: column;
            gap: 40px;
            > * {
                width: 100%;
            }
          }

        .details {
            .percentage {
                font-size: 20px;
                font-weight: $fw-bold;
            }
        }
    }
}