@import '../../styles/utils';

.auth-page-layout-container {
    height: 100%;
    background-color: $color-bg-content;
    padding: 30px 30px;
    box-sizing: border-box;

    > * {
        max-width: 450px;
    }

    @media #{$lt-1080-devices} {
       justify-content: flex-start;
    }
    
    .header-container {

    img {
        width: 100px;
        object-fit: contain;
    }

        .title-container {
            .title-text-primary {
                font-size: 47px;
                color: $bicolor-primary;
                font-weight: $fw-extra-bold;
            }

            .title-text-secondary {
                font-size: 47px;
                color: $bicolor-secondary;
            }

            .subtitle-text {
                font-size: 17px;
                font-weight: $fw-semi-bold;
                color: #FFFFFF;
            }
        }
    }
}