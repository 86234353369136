@import '../styles/utils.scss';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 36, 49, 0.8); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal-content {
        width: 400px;
        height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #343E4D;
        border: 1px solid #5A6679;
        padding: 40px;
        border-radius: 12px;
        max-width: 500px;
        text-align: center;
        position: relative;

        .modal-header {
            margin-bottom: 20px;
        }

        .modal-body {
            color: #ffffff;
            letter-spacing: 0.11px;
            span {
                font-size: 32px;
                font-weight: $fw-bold;
                color: #ffffff;
            }

            p { 
                font-size: 14px;
                color: #ffffff;
            }
        }

        .modal-footer {
            margin-top: 60px;

            .button.form {
                font-size: 14px;
                min-width: 200px;
            }
        }

        .check-icon {
            width: 50px;
            height: 50px;
            color: $color-accent;
        }

     
    }
}
