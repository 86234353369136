@import '../../../../styles/utils.scss';

.networks-container {
    width: 100%;

    .networks-label {
        font-size: 12px;
        color: $color-text-label;
        font-weight: $fw-bold;
    }

    .network-container {
        cursor: pointer;
        box-sizing: border-box;
        width: 100%;
        padding: 15px 20px;
        border: 0.6px solid $border-color-form-field;
        border-radius: 12px;
        font-weight: $fw-bold;

        &.selected {
            border-color: $color-accent;
        }
        
        .check-icon {
            color: $color-text-label;

            &.selected {
                color: $color-accent;
            }
        }
        .title {
            font-size: 14px;
            color: #D6D8DB;
        }

        .subtitle {
            font-size: 12px;
            color: $color-text-label;

            span {
                color: #D6D8DB;
            }
        }
    }
}

