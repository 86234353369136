@import '../styles/utils.scss';


.asset-analytics {
        width: 66%;

        .card-title {
            width: 50%;
        }

        .filters-container {
            display: flex;
            justify-content: end;
            width: 50%;
        }
        .app-dropdown-container {
            width: 100px;
    
            &.form {
                .dropdown-header {
                    box-sizing: border-box;
                    height: 30px;
                    width: 100px;
                    border: 0.6px solid rgba(224, 224, 224, 0.1);
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: $fw-semi-bold;
                    color: #FFFFFF;
                    background-color: #323D4E;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    padding-right: 10px;
                }
    
    
                .dropdown-list {
                    width: 130px;
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: $fw-semi-bold;
                    color: #FFFFFF;
                }
            }
        }
}
